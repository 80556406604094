/*
* Section wrap element is being "modified" within "/web/modules/custom/eaton_hooks/src/Plugin/Filter/GutenbergJsonApiFilter.php"
* to add ".react-replace-dom" element within section markup, so that it can be replaced with inner content here.
* */
import React from 'react';
import {parseGutenbergReact} from "../../../api/global";
import parse from 'html-react-parser';

const sectionInnerData = (block_data, skip) => {
  if (!block_data || block_data?.length < 1) {
    return null;
  }
  if (block_data?.length > 0)

  return (
      <>
        {block_data?.length > 0 && block_data.map((block, key) => {
          return parseGutenbergReact(key, block, skip);
        })
        }
      </>
  );
}

const GutenbergSectionWrap = (props) => {
  if (!(props?.block?.props)) {
    return null;
  }

  const block_data = props?.block?.data || [];
  const wrapper = props?.block?.props?.rendered;

  return (
    parse(wrapper, {
      replace: ({attribs}) => attribs && attribs.class === 'react-replace-dom' && sectionInnerData(block_data, props?.skipData)
    })
  );
};

export default GutenbergSectionWrap;
